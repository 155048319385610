import React from 'react';

const LayoutBooking = ({ children }) => (
  <>
    <main className="site-content">
      {children}
    </main>
    {/* <Footer /> */}
  </>
);

export default LayoutBooking;  